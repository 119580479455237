import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchClusterData } from "../../actions/innovative/course.action";
import session from "../session";
import { getSelectedClusterSectionsIds } from "../../selectors/innovative/course.selector";

const DEFAULT_SELECTION = "Default";
const MAX_WEBSOCKET_RECONNECTING_ATTEMPTS = 5;
const MAX_WEBSOCKET_RECONNECT_INTERVAL = 4000;
const MIN_WEBSOCKET_RECONNECT_INTERVAL = 1000;
const INITIAL_WS_RECONNECT_ATTEMPT_COUNT = 0;

export const useWebSocket = () => {
  const dispatch = useDispatch();

  const [isConnectionOpen, setIsConnectionOpen] = useState(false);
  const [reConnectingCount, setReConnectingCount] = useState(INITIAL_WS_RECONNECT_ATTEMPT_COUNT);
  const [connectionUrl, setConnectionUrl] = useState('');
  const [reconnectTimeOutFunction, setReconnectTimeOutFunction] = useState();

  const selectedSectionFilters = useSelector(getSelectedClusterSectionsIds);

  let socketConnection;

  const stopTimeOutFunction = () => {
    if (reconnectTimeOutFunction) {
      clearTimeout(reconnectTimeOutFunction);
    }
  };

  const send = (payload) => {
    socketConnection.send(
      JSON.stringify(payload)
    );
  };

  const close = () => {
    if (isConnectionOpen) {
      socketConnection.close();
    }
  };

  const createConnection = serverUrl => {
    stopTimeOutFunction();
    setConnectionUrl(serverUrl)
    socketConnection = new WebSocket(serverUrl);

    socketConnection.onmessage = function (event) {
      const eventData = JSON.parse(event.data);

      const validatePayload = selectedSectionsArray => {
        if (selectedSectionsArray.map(section => section.text).includes(DEFAULT_SELECTION)) {
          return [];
        }

        return selectedSectionsArray.map(section => section.id);
      };

      switch (eventData.platform) {
        case "canvas": {
          switch (eventData.event_name) {
            case "student-clustering": {
              dispatch(fetchClusterData({
                "user_id": session.get([
                  session.keys.innovativeMeta,
                  "token"
                ]),
                filters: {
                  sections: validatePayload(selectedSectionFilters),
                },
              }
              ))
              return
            }
            default: {
              return
            }
          }
        }
        default: {
          return
        }
      }
    };

    socketConnection.onopen = function (event) {
      setIsConnectionOpen(true);
    };

    socketConnection.onclose = function (event) {
      setIsConnectionOpen(false);
      // identify the connection was closed from server
      if (event.wasClean) {
        createConnection(serverUrl);
        return;
      }
      // ws connection failed 
      setReConnectingCount(reConnectingCount + 1);
    };
  };

  const webSocketReconnection = () => {
    if (MAX_WEBSOCKET_RECONNECTING_ATTEMPTS > reConnectingCount) {
      createConnection(connectionUrl);
    }
  };

  const getReconnectTime = reConnectingAttempt => {
    const nextRetryIn = reConnectingAttempt * MIN_WEBSOCKET_RECONNECT_INTERVAL;
    if (nextRetryIn > MAX_WEBSOCKET_RECONNECT_INTERVAL) {
      return MAX_WEBSOCKET_RECONNECT_INTERVAL;
    }

    return nextRetryIn;
  }

  useEffect(() => {
    if (INITIAL_WS_RECONNECT_ATTEMPT_COUNT !== reConnectingCount) {
      const timeoutFunction = setTimeout(
        webSocketReconnection,
        getReconnectTime(reConnectingCount)
      );
      setReconnectTimeOutFunction(timeoutFunction);
    }
  }, [reConnectingCount]);

  return {
    close,
    send,
    isConnectionOpen,
    createConnection,
  };
};
